import {Box, CircularProgress} from '@mui/material';

export const Loading = ({height = '100vh'}: {height?: string | number}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height,
      }}
    >
      <CircularProgress color="secondary" />
    </Box>
  );
};
