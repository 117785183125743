import {createTheme} from '@mui/material/styles';
import {red} from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Theme {
    code: {
      border: string;
      background: string;
    };
    homepage: {
      lightText: string;
      background: string;
    };
    bg: {
      white: string;
      light: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    code: {
      border: string;
      background: string;
    };
    homepage: {
      lightText: string;
      background: string;
    };
    bg: {
      white: string;
      light: string;
    };
  }
}

// A custom theme for this app
export const darkTheme = createTheme({
  code: {
    border: 'rgba(47, 58, 70, 0.6)',
    background: '#1C2025',
  },
  homepage: {
    lightText: '#8dbeff',
    background: '#fff',
  },
  bg: {
    white: '#fff',
    light: '#00439b',
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#05177f',
    },
    secondary: {
      main: '#00ea90',
      dark: '#00663C',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#05177f',
      paper: '#041447',
    },
    text: {
      primary: '#fff',
      secondary: '#d2d2d2',
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'none',
        color: 'secondary',
      },
    },
  },
});
