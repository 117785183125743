import {StrictMode, Suspense} from 'react';
import * as ReactDOM from 'react-dom/client';
import {ThemeProvider} from '@emotion/react';
import {CssBaseline, GlobalStyles} from '@mui/material';
import {darkTheme} from './theme';
import {RouterProvider} from 'react-router-dom';
import {router} from './router';
import {Loading} from './components/Loading';

const root = document.getElementById('root');

if (!root) {
  throw new Error('Root element not found');
}

const globalStyles = (
  <GlobalStyles
    styles={{
      '*:not(pre) > code:not(.transparent), pre[style]': {
        border: '1px solid',
        borderColor: darkTheme.code.border,
        borderRadius: '5px',
        backgroundColor: darkTheme.code.background,
        padding: '2px 4px',
      },
    }}
  />
);

ReactDOM.createRoot(root).render(
  <StrictMode>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {globalStyles}
      <Suspense fallback={<Loading />}>
        <RouterProvider router={router} />
      </Suspense>
    </ThemeProvider>
  </StrictMode>
);
