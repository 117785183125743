import {HTMLAttributes, PropsWithChildren, forwardRef} from 'react';
import {BASENAME} from '../utils/constants';

type ImageProps = PropsWithChildren<HTMLAttributes<HTMLImageElement>> & {
  src: string;
  alt: string;
  sizes: number[];
  width: number;
  height: number;
  lazy?: boolean;
};

export const Image = forwardRef<HTMLImageElement, ImageProps>(
  ({src, alt, sizes, width, height, lazy = true, style, ...rest}, ref) => {
    const extension = src.split('.').pop();
    const fileName = `${BASENAME}static/${src
      .split('.')
      .slice(0, -1)
      .join('.')}`;

    return (
      <img
        ref={ref}
        loading={lazy ? 'lazy' : undefined}
        src={`${BASENAME}static/${src}`}
        alt={alt}
        srcSet={
          sizes.length
            ? `${fileName}.${extension} ${width}w${
                sizes.length ? ', ' : ''
              }${sizes
                ?.map(size => `${fileName}-${size}w.${extension} ${size}w`)
                .join(', ')}`
            : undefined
        }
        width={width}
        height={height}
        sizes={
          sizes.length ? `(max-width: ${width}px) 100vw, ${width}px` : undefined
        }
        style={{
          aspectRatio: width ? `auto ${width}/${height}` : undefined,
          width: width || undefined,
          maxWidth: '100%',
          height: 'auto',
          ...style,
        }}
        {...rest}
      />
    );
  }
);

Image.displayName = 'Image';
