import React, {forwardRef, PropsWithChildren} from 'react';
import {Link as MUILink, LinkBaseProps} from '@mui/material';
import {Link as LinkComponent} from 'react-router-dom';

export interface LinkProps extends LinkBaseProps {
  to?: string;
  onClick?: (e: React.MouseEvent) => void;
  href?: string;
  target?: string;
  rel?: string;
  color?: string;
  ariaLabel?: string;
}

export const Link = forwardRef<HTMLAnchorElement, PropsWithChildren<LinkProps>>(
  ({to, children, ariaLabel, ...rest}, ref) => (
    <MUILink
      component={to ? LinkComponent : 'a'}
      ref={ref}
      to={to}
      aria-label={ariaLabel}
      {...rest}
    >
      {children}
    </MUILink>
  )
);

Link.displayName = 'Link';
